import { Route, Routes } from "react-router-dom";
import IndexPage from "./pages/index";
import { type ReactElement, lazy } from "react";
import PreloaderPage from "./pages/loader";
import SignUpAvatar from "./pages/signup-avatar";
import TeamDetailPage from "./pages/team/detail";
import ExplorePage from "./pages/explore";

const TournamentDetailPage = lazy(async () => await import("./pages/tournament/detail/detail"));
const MatchDetailPage = lazy(async () => await import("./pages/match/detail"));
const UserProfilePage = lazy(async () => await import("./pages/user/profile"));
const AchievementsPage = lazy(async () => await import("./pages/achievements"));
const UserSettingsPage = lazy(async () => await import("./pages/user/settings"));
const UserProfileEditPage = lazy(async () => await import("./pages/user/profile-edit"));
const UserPreferencesPage = lazy(async () => await import("./pages/user/preferences"));
const EmailApprovePage = lazy(async () => await import("./pages/user/email-change-approve"));
const UserPromocodesPage = lazy(async () => await import("./pages/user/promocodes"));
const NotificationSettingsPage = lazy(async () => await import("./pages/user/notifications"));
const UserAuthorizationPage = lazy(async () => await import("./pages/user/authorization"));
const SignInPage = lazy(async () => await import("./pages/signin"));
const SignUpPage = lazy(async () => await import("./pages/signup"));
const SignUpSettings = lazy(async () => await import("./pages/signup-settings"));
const SignUpProfile = lazy(async () => await import("./pages/signup-profile"));
// const LaunchMyCityFramePage = lazy(async () => await import("./pages/frames/launch-my-city"));
const LeaderBoardPage = lazy(async () => await import("./pages/leaderboard/leaderboard"));
const PaywallPage = lazy(async () => await import("./pages/paywall/paywall"));
const MessangerPage = lazy(async () => await import("./pages/messenger"));
const TournamentChallengeFullLeaderboard = lazy(
  async () => await import("./pages/tournament/challenge-full-leaderboard"),
);

export const IntermediateRoutes = (): ReactElement<any, any> => (
  <Routes>
    <Route element={<PreloaderPage />} path="*"></Route>
  </Routes>
);

export const InactiveSubscriptionRoutes = (): ReactElement<any, any> => (
  <Routes>
    <Route element={<PaywallPage />} path="*" />
  </Routes>
);

export const MESSENGER_PAGE_ROUTE = "/chat";
export const RegistrationCompletedRoutes = (): ReactElement<any, any> => (
  <Routes>
    <Route element={<IndexPage />} path="/" />
    <Route element={<IndexPage />} path="/tournaments" />
    <Route element={<TournamentDetailPage />} path="tournament/:id" />
    <Route
      element={<TournamentChallengeFullLeaderboard />}
      path="tournament/:id/challenge-leaderboard"
    />
    <Route element={<TournamentDetailPage isMessengerOpened />} path="tournament/:id/chat" />
    <Route element={<MatchDetailPage />} path="/tournament/:tournamentId/match/:id" />

    <Route element={<UserProfilePage />} path="user" />
    <Route element={<UserProfilePage />} path="user/:id" />
    <Route element={<TeamDetailPage />} path="team/:id" />
    <Route element={<AchievementsPage />} path="achievements" />
    <Route element={<UserSettingsPage />} path="settings" />
    <Route element={<UserProfileEditPage />} path="/settings/edit" />
    <Route element={<UserPreferencesPage />} path="/settings/game-preferences" />
    <Route element={<NotificationSettingsPage />} path="/settings/notifications" />
    <Route element={<UserAuthorizationPage />} path="/settings/authorization" />
    <Route element={<EmailApprovePage />} path="/approve-email/:key" />
    <Route element={<UserPromocodesPage />} path="/settings/promocodes" />
    {/* <Route element={<LaunchMyCityFramePage />} path="/launch_my_city" /> */}
    {/* <Route element={<UserPromocodesPage />} path="/:promocode" /> */}
    <Route element={<LeaderBoardPage />} path="/leaderboard" />
    <Route element={<ExplorePage />} path="/explore" />
    <Route element={<MessangerPage />} path={MESSENGER_PAGE_ROUTE} />
    <Route element={<IndexPage />} path="*" />
  </Routes>
);

export const RegistrationWithoutProfileRoutes = (): ReactElement<any, any> => (
  <Routes>
    <Route element={<SignUpProfile />} path="/stage/1/*" />
    <Route element={<SignUpSettings />} path="/stage/2/*" />
    <Route element={<SignUpAvatar />} path="/stage/3/*" />
    <Route element={<SignUpProfile />} path="*" />
  </Routes>
);

export const AnonimousRoutes = (): ReactElement<any, any> => (
  <Routes>
    <Route element={<SignInPage />} path="*" />
    <Route element={<SignInPage />} path="/" />
    <Route element={<TournamentDetailPage />} path="tournament/:id" />
    <Route element={<SignInPage />} path="/signin" />
    <Route element={<SignUpPage />} path="/signup" />
    <Route element={<SignUpSettings />} path="/signup/profile" />
  </Routes>
);
