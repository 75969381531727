import { type FC } from "react";
import classNames from "classnames";
import BagelImage from "@double-bagel/assets/Images/bagel-cartoon-rocket4.svg";
import Image from "@double-bagel/components/image";
import { Button } from "../buttons";
import { ButtonStyle } from "../buttons/default-button";
import { useNavigate } from "react-router-dom";

const JoinMatchBanner: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-full flex-col overflow-hidden rounded-[10px] border border-[black] bg-white tb:rounded-xl tb:border-2 dk:rounded-[15px] dk:border-[3px]">
      <div
        className="flex h-full flex-row overflow-hidden "
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      >
        <div className="flex-[0_1_250px] pb-[20px] pl-4 pt-4 font-Unbounded tb:flex-[0_1_405px] tb:py-8 tb:pl-8 dk:flex-[0_1_700px] dk:py-10 dk:pl-10 dk:text-3xl">
          Looking for a hitting partner?
          <div className="font-GolosText text-[12px] leading-[14px] tb:text-lg dk:text-2xl dk:leading-snug">
            Join a match or create one to play with someone at you level!
          </div>
        </div>
        <div className="z-20 mr-4 mt-4 flex flex-auto items-center justify-end">
          <Image
            src={BagelImage}
            alt={`bagel-image`}
            classNameWrapper={classNames("absolute w-[85px] tb:w-[125px] dk:w-[160px]")}
          />
        </div>
      </div>
      <div className="flex w-full">
        <Button
          className="m-4 w-full"
          style={ButtonStyle.Primary}
          onClick={() => {
            navigate("/explore");
          }}
        >
          Join a match
        </Button>
      </div>
    </div>
  );
};

export default JoinMatchBanner;
