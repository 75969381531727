import { useEffect, useState, type FC } from "react";
import { renderToString } from "react-dom/server";
import classNames from "classnames";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { type PaginationOptions } from "swiper/types";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";

import TournamentMatchReportWidget from "@double-bagel/components/widgets/tournament-match-report";
import {
  useGetTournamentMatches,
  useGetTournaments,
} from "@double-bagel/endpoints/endpoints.tournament";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import useAuth from "@double-bagel/hooks/useAuth";

type ActualTournamentMatchesSliderProps = {
  className: string;
  type?: TournamentModel["type"];
  onTournamentsFetch?: (tournaments: Array<ClientModel<TournamentModel>>) => void;
};

const pagination: PaginationOptions = {
  clickable: true,
  bulletActiveClass: "bg-border-secondary opacity-100",
  renderBullet: function (index: number, className: string) {
    return renderToString(<div className={classNames(className)} />);
  },
};

const setBottomSpaceForPagination = (
  controlHeight: number,
  extraClass?: string,
): Record<string, any> => {
  return {
    className: classNames(
      "after:content-[''] after:block after:h-[var(--controlHeight)]",
      extraClass,
    ),
    style: {
      "--controlHeight": `${controlHeight}px`,
      "--swiper-pagination-bottom": "0",
    },
  };
};

const ActualTournamentMatchesSlider: FC<ActualTournamentMatchesSliderProps> = ({
  className,
  type,
  onTournamentsFetch,
}) => {
  const { authState } = useAuth();
  const { execute: fetchCurrentUserTournaments, value: currentUserTournaments } =
    useGetTournaments();
  const typeFilter = type !== undefined ? { type } : {};
  useEffect(() => {
    void fetchCurrentUserTournaments({
      forUser: true,
      status: "LIVE",
      ...typeFilter,
    });
  }, []);
  const [currentTournament, setCurrentTournament] = useState<ClientModel<TournamentModel>>();
  const {
    execute: fetchCurrentUserTournamentMatches,
    value: currentUserTournamentMatches,
    isFetching,
  } = useGetTournamentMatches();
  useEffect(() => {
    if (currentUserTournaments) {
      setCurrentTournament(currentUserTournaments?.[0]);
      onTournamentsFetch?.(currentUserTournaments);
    }
  }, [currentUserTournaments]);
  useEffect(() => {
    if (currentTournament && authState?.userId) {
      void fetchCurrentUserTournamentMatches(currentTournament.id, {
        userId: Number(authState.userId),
      });
    }
  }, [currentTournament, authState?.userId]);
  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Pagination]}
      pagination={pagination}
      {...setBottomSpaceForPagination(currentUserTournaments?.length === 1 ? 0 : 28, className)}
    >
      {currentUserTournaments?.map((item, index) => (
        <SwiperSlide key={index}>
          <>
            <TournamentMatchReportWidget
              tournament={item}
              currentUserMatches={currentUserTournamentMatches ?? []}
              isMatchesLoading={!!isFetching}
            />
          </>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default ActualTournamentMatchesSlider;
