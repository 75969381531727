import { type ClientModel } from "../../adapter/client-models";
import { type CityModel } from "./city";
import { type TournamentMatchModel } from "./match";
import { type BackendModels } from "./models";
import { type TournamentTeamModel } from "./team";
import { UserGenders } from "./user";

export const TournamentGenders = { ...UserGenders } as const;
export const TournamentLevel = {
  ROOKIES: "ROOKIES",
  EXPERT: "EXPERT",
  CASUAL: "CASUAL",
} as const;
export const TournamentStatus = {
  UPCOMING: "UPCOMING",
  LIVE: "LIVE",
  FINISHED: "FINISHED",
} as const;
export const TournamentType = {
  DEFAULT: "DEFAULT",
  DUEL: "DUEL",
  "ROUND-ROBIN": "ROUND-ROBIN",
  CHALLENGE: "CHALLENGE",
} as const;

export enum LeadingHand {
  Right = "Right-handed",
  Left = "Left-handed",
}

export interface TournamentProductType extends BackendModels.BaseModel {
  name: string;
  price_id: string;
  product_id: string;
  currency: string;
  is_active: boolean;
  created: string;
  stripe_id: string;
  cost: number;
  actual_discount: number;
  cost_with_discount: number;
}

export interface TournamentModel extends BackendModels.BaseModel {
  title: string;
  gender: keyof typeof TournamentGenders;
  level: keyof typeof TournamentLevel;
  status: keyof typeof TournamentStatus;
  type: keyof typeof TournamentType;
  players_to_start: number;
  teams_to_start: number;
  players_joined: number;
  teams: Array<TournamentTeamModel>;
  players: Array<BackendModels.Player>;
  city: ClientModel<CityModel>;
  matches?: Array<TournamentMatchModel>;
  winner?: ClientModel<TournamentTeamModel>;
  finished_at?: string;
  deadline?: string;
  tournament_product?: ClientModel<TournamentProductType>;
  is_current_user_joined: boolean;
  detailImage: string;
  cardImage: string;
}

export interface TournamentMatchTeamResultModel extends BackendModels.BaseModel {
  tournament_match_team: BackendModels.ID;
  result_type: keyof typeof ResultTypesMapping;
  feedback_scores: Array<BackendModels.UserFeedbackScore>;
  scores: Array<{
    ownScore: number;
    oppoScore: number;
    gameNumber: number;
  }>;
  opponent: BackendModels.Player;
  fail_reason: keyof typeof ResultFailReasonMapping;
  is_withdraw: boolean;
  other_fail_reason?: string;
}

export interface TournamentWithdrawModel extends BackendModels.BaseModel {
  reason: string;
  reason_extra: string;
}

export const ResultFailReasonMapping: Record<
  | "NEVER_ANSWERED"
  | "NEVER_SHOWED_UP"
  | "OPPONENT_RETIRED"
  | "OWN_RETIRED"
  | "DROP_TOURNAMENT"
  | "OTHER",
  | "NEVER_ANSWERED"
  | "NEVER_SHOWED_UP"
  | "OPPONENT_RETIRED"
  | "OWN_RETIRED"
  | "DROP_TOURNAMENT"
  | "OTHER"
> = {
  NEVER_ANSWERED: "NEVER_ANSWERED",
  NEVER_SHOWED_UP: "NEVER_SHOWED_UP",
  OPPONENT_RETIRED: "OPPONENT_RETIRED",
  OWN_RETIRED: "OWN_RETIRED",
  DROP_TOURNAMENT: "DROP_TOURNAMENT",
  OTHER: "OTHER",
};

export const ResultTypesMapping: Record<
  "PENDING" | "SUCCESS" | "FAIL" | "RESOLVED",
  "PENDING" | "SUCCESS" | "FAIL" | "RESOLVED"
> = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
  RESOLVED: "RESOLVED",
};

export interface TournamentLeadearboardSerializer extends BackendModels.BaseModel {
  user_avatar: string;
  user_id: number;
  user_fullname: string;
  place: number;
  match_played: number;
  wins: number;
  losses: number;
  pts: number;
}

export interface TournamentLeadearboardChallengeSerializer extends BackendModels.BaseModel {
  team: ClientModel<BackendModels.Team>;
  pts: number;
  place: number;
  match_played: number;
  wins: number;
  losses: number;
}
