import axios from "axios";
import asHook from "./adapter/hook";
import { type BackendModels } from "./models/@types/models";
import { queryParamsFromDict } from "./adapter/util";

export const useGetTeamResults = asHook(
  async (teamId: BackendModels.ID, tournamentId?: BackendModels.ID) => {
    const result = await axios.get<BackendModels.TeamResult[]>(
      `/team/${teamId}/results?${queryParamsFromDict({
        tournament: tournamentId,
      })}`,
    );
    return result.data;
  },
  "TeamResult",
);

export const useGetTeamTotalResult = asHook(async (teamId: BackendModels.ID) => {
  const result = await axios.get<BackendModels.TeamTotalResult>(`/team/${teamId}/results/total`);
  return result.data;
}, "TeamTotalResult");

export const useGetTeamDetail = asHook(async (teamId: BackendModels.ID) => {
  const result = await axios.get<BackendModels.Team>(`/team/${teamId}`);
  return result.data;
}, "Team");
