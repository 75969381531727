import { type TournamentModel } from "@double-bagel/endpoints/models/@types/tournament";
import { type ClientModel } from "@double-bagel/endpoints/adapter/client-models";
import Image from "@double-bagel/components/image";
import { type FC } from "react";
import { useDeadlineDate } from "@double-bagel/components/cards/match/match-cards.hooks";

import ClockIcon from "assets/Icons/clock.png";
import { ReactComponent as ChevronRight } from "assets/Icons/mono/chevron-right.svg";
import { Link } from "react-router-dom";

type TournamentHeaderProps = {
  tournamentId: ClientModel<TournamentModel["id"]>;
  tournamentType: ClientModel<TournamentModel>["type"];
  tournamentTitle?: ClientModel<TournamentModel>["title"];
  round?: number;
  deadline?: string;
  isFinished: boolean;
};
const TournamentHeader: FC<TournamentHeaderProps> = ({
  tournamentId,
  tournamentType,
  tournamentTitle,
  round,
  deadline,
  isFinished,
}) => {
  return (
    <div className="flex-flow-col flex justify-between rounded-xl bg-white px-3 py-3 tb:px-6 tb:py-5 dk:px-10 dk:py-8">
      <div className="flex items-center text-[10px] leading-4 text-fonts-optional tb:text-base tb:leading-6 dk:text-lg dk:leading-7">
        {tournamentType === "ROUND-ROBIN" ||
        tournamentType === "CHALLENGE" ||
        tournamentType === "DUEL"
          ? tournamentTitle
          : `round ${round}`}
      </div>
      <div className="flex items-center gap-1 justify-self-end rounded-full bg-[#EFEFEF] px-2 py-1 font-GolosText text-xs font-normal tb:gap-2 tb:px-[10px] tb:py-1.5 dk:gap-3 dk:px-4 dk:py-2">
        {!isFinished ? (
          <>
            <Image src={ClockIcon} alt="" className="h-3 w-3 tb:h-4 tb:w-4 dk:h-6 dk:w-6" />
            <div className="text-xs leading-4 tb:text-lg tb:leading-6 dk:text-xl dk:leading-7">
              <span className="font-GolosText text-[#575757]">
                {tournamentType === "ROUND-ROBIN" ? "Tournament Ends on" : "Matchweek Ends on"}
              </span>
              <span className="ml-1 font-GolosText font-bold text-fonts-accentColor">
                {useDeadlineDate(deadline)}
              </span>
            </div>
          </>
        ) : (
          <>
            <Image src={ClockIcon} alt="" className="h-3 w-3 tb:h-4 tb:w-4 dk:h-6 dk:w-6" />
            <div className="text-xs leading-4 tb:text-lg tb:leading-6 dk:text-xl dk:leading-7">
              <span className="font-GolosText text-[#575757]">Matchweek Ended</span>
            </div>
          </>
        )}
      </div>
      <Link
        className="flex h-7 w-7 cursor-pointer justify-center self-center rounded-full bg-[#E8F6FF]"
        to={`tournament/${tournamentId}`}
      >
        <ChevronRight className="w-3" />
      </Link>
    </div>
  );
};

export default TournamentHeader;
